import React, { useEffect } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import BreadCrumb from "../../components/elements/BreadCrumb";
import {
  Alert,
  Input,
  Modal,
  notification,
  Radio,
  Switch,
  Tooltip,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Payment/CheckoutForm";
import config from "../../config";

const Checkout = () => {
  const navigate = useNavigate();
  const [calculated_status, setCalculateStatus] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("auth_id") && localStorage.getItem("auth_name")) {
      const prevItems = JSON.parse(localStorage.getItem("items") || '[]');

      // setCalculateStatus(prevItems.some((item) => item.zip_status != 0) == true ? true : false)
      setCalculateStatus(
        prevItems.some((item) => item.zip_status == 0) == true ? false : true
      );
    }
  }, []);

  const location = useLocation()

  useEffect(() => {
    if (!localStorage.getItem("items") || JSON.parse(localStorage.getItem("items"))?.length === 0) {
      navigate(-1)
    }
    localStorage.setItem("curr_path", location.pathname);
  }, []);

  const [cartItems, setCartItems] = useState([]);
  const [total_price, setTotalPrice] = useState(0);
  const [isNegotiate, setIsNegotiate] = useState(false);
  const [negotiated_price, setNegotiatedPrice] = useState(0);
  const [subTotal_price, setSubTotalPrice] = useState(0);
  const [nonNegotiatedtotal, setNonNegotiatedTotal] = useState(0);

  const [discountApplied, setDiscountApplied] = useState(false);
  const charityCharges = 1;

  // 6 Drywall Products
  // 8 Roofing
  // 11 Paint
  // 13 Plumbing
  // 20 Insulation
  // 26 General Shop Materials
  // 18 Fixings
  // 05 Aggregates & Heavyside
  // 28 Electrical 

  // calculating discount for above 300 pound
  function getDiscountOffer(products) {
    const excludesProducts = [6, 8, 11, 13, 20, 26, 18, 5, 28];
    const excludeOfferProducts = products?.filter(i => !excludesProducts.includes(i.category_id))
    const excludeProductTotal =
      excludeOfferProducts?.reduce((total, product) => (total + (Number(product.price) * product.qty)), 0)

    const eligiblePrice = 300 // minimum 300 pounds
    const offerDiscount = 10
    let discountedTotal = 0;

    console.log( 
      excludeOfferProducts,
      (excludeProductTotal * (offerDiscount / 100)).toFixed(2))

    if (excludeProductTotal > eligiblePrice) {
      // Apply 10% discount
      return discountedTotal = (excludeProductTotal * (offerDiscount / 100));
    }
    return discountedTotal
  }

  // getting cart products conditionally
  useEffect(() => {
    const getCartData = async () => {
      const formData = new FormData();
      formData.append("cart_id", localStorage.getItem("cart_id"));
      try {
        const res = await axios.post(`${config.apiUrl}/cartDetails`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        setIsNegotiate(res.data.data.negotiated == 1 ? true : false);

        if (res.data.data.negotiated == 1) {
          setNegotiatedPrice(res.data.data.price);
          const Items = JSON.parse(localStorage.getItem("items"));
          const includes = Items.some((item) => item.negotiate);
          if (includes) {
            const nonNegotiableprice = Items?.filter((obj) => obj.negotiate == true)
              .flat()
              .reduce((total, item) => {
                return total + parseFloat(item.price) * item.qty;
              }, 0);
            setTotalPrice(
              parseFloat(res.data.data.price) + parseFloat(nonNegotiableprice)
            );
          } else {
            setTotalPrice(parseFloat(res.data.data.price));
          }
        } else {
          const Items = JSON.parse(localStorage.getItem("items"));

          const includes = Items.some((item) => item.negotiate);

          if (includes) {
            const nonNegotiableprice = Items?.filter(
              (obj) => obj.negotiate == true
            )
              .flat()
              .reduce((total, item) => {
                return total + parseFloat(item.price) * item.qty;
              }, 0);
            setTotalPrice(
              parseFloat(res.data.data.price) + parseFloat(nonNegotiableprice)
            );
          } else {
            setTotalPrice(parseFloat(res.data.data.price));
          }
        }

        const Items = JSON.parse(localStorage.getItem("items"));
        let existingIds = Items.map((obj) => obj.id);

        // Filter array2 to get objects with ids not present in array
        let output = res.data.data.cartData?.filter(
          (obj) => !existingIds.includes(obj.product_id)
        );

        const updatedArray = output.map((item) => ({
          id: item.product_id,
          name: item.name,
          negotiate: false, // Assuming this is a default value
          price: item.price,
          qty: item.qty,
          width: item.width,
          height: item.height,
          length: item.length,
          thumbnail_image: item.photos,
          category_id: item.category_id,
        }));

        const updatedItems = [...Items, ...updatedArray];

        if (output?.length != 0) {
          localStorage.setItem("items", JSON.stringify(updatedItems));
          setCartItems(updatedItems);
          const totalPrice = updatedItems
            ?.filter((obj) => obj.negotiate == false)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          const nonNegotiableprice = updatedItems
            ?.filter((obj) => obj.negotiate == true)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          setTotalPrice(totalPrice);
          setNonNegotiatedTotal(nonNegotiableprice);
          setSubTotalPrice(totalPrice);
        } else {
          const Items = JSON.parse(localStorage.getItem("items"));
          setCartItems(Items);
          const includes = Items.some((item) => item.negotiate);

          const totalPrice = Items?.filter((obj) => obj.negotiate == false)
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          const nonNegotiableprice = Items?.filter(
            (obj) => obj.negotiate == true
          )
            .flat()
            .reduce((total, item) => {
              return total + parseFloat(item.price) * item.qty;
            }, 0);
          setTotalPrice(totalPrice);

          setSubTotalPrice(totalPrice);
          setNonNegotiatedTotal(nonNegotiableprice);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (localStorage.getItem("cart_id") != null) {
      getCartData();
    } else {
      const Items = JSON.parse(localStorage.getItem("items"));

      setCartItems(Items);

      const totalPrice = Items?.filter((obj) => obj.negotiate == false)
        .flat()
        .reduce((total, item) => {
          return total + parseFloat(item.price) * item.qty;
        }, 0);
      const nonNegotiableprice = Items?.filter((obj) => obj.negotiate == true)
        .flat()
        .reduce((total, item) => {
          return total + parseFloat(item.price) * item.qty;
        }, 0);
      setTotalPrice(totalPrice);
      setNonNegotiatedTotal(nonNegotiableprice);

      const discount = getDiscountOffer(Items)
      setDiscountApplied(discount)

      // setSubTotalPrice(totalPrice);
      setSubTotalPrice(totalPrice - discount);
    }
  }, []);

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Checkout",
    },
  ];

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );

  const footer = <FooterMarketPlace2 />;

  const [payment, setPayment] = useState(false);
  const [payment_receipt, setPaymentReceipt] = useState(false);
  const [same_address, setSameAddress] = useState(true);
  const [method, setMethod] = useState(3);

  function handleChangeMethod(e) {
    setMethod(e.target.value);
  }

  function getCharityStatus() {
    return localStorage.getItem('charity') ? true : false
  }

  const [shippingfess, setShippingFees] = useState(
    localStorage.getItem("ship") || ""
  );
  const [congestion, setCongestion] = useState(
    localStorage.getItem("congestion")
  );
  const [vatPrice, setVatPrice] = useState(0);
  const [MainTotal, setMainTotal] = useState(0);
  const [charityApplied, setCharityApplied] = useState(getCharityStatus());

  useEffect(() => {
    const mainVatPrice =
      congestion == 1 ? "15.00" : (parseFloat(total_price) * 20) / 100;
    setVatPrice(mainVatPrice);

    const charity = charityApplied ? charityCharges : 0;

    setMainTotal(
      parseFloat(total_price) +
      parseFloat(mainVatPrice) +
      parseFloat(shippingfess) + charity
      - discountApplied
    );
  }, [shippingfess, total_price]);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [billing_country, setBillingCountry] = useState("United Kingdom");
  const [billing_state, setBillingstate] = useState(null);
  const [billing_city, setBillingCity] = useState(null);
  const [billing_code, setBillingCode] = useState(null);
  const [billing_address, setBillingAddress] = useState(null);
  const [shipping_country, setshippingCountry] = useState("United Kingdom");
  const [shipping_state, setshippingstate] = useState(null);
  const [shipping_city, setshippingCity] = useState(null);
  const [shipping_code, setshippingCode] = useState(null);
  const [shipping_address, setShippingAddress] = useState(null);
  // console.log(shipping_address)

  useEffect(() => {
    onUserDetail();
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [state_option, setStateOption] = useState([]);
  const [city_ship_option, setCityShipOption] = useState([]);
  const [city_option, setCityOption] = useState([]);

  const onUserDetail = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/users/${localStorage.getItem("auth_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setStateOption(res.data.states);
        setName(res.data.users[0]?.name);
        setEmail(res.data.users[0]?.email);
        setMobile(res.data.users[0]?.phone);

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false)
    }
  };

  const onChangeState = async (e) => {
    setLoading(true);
    setBillingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setCityOption(res.data.cities);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false)
    }
  };

  const onChangeShippingState = async (e) => {
    setLoading(true);
    setshippingstate(e.target.options[e.target.selectedIndex].id);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/cities/${e.target.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setCityShipOption(res.data.cities);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  const [noDelivering, setNotDelivering] = useState(false);
  const [deliverOpen, setDeliveryOpen] = useState(false);
  const [policy, setPolicy] = useState(false);

  const [deliveryProducts, setDeliveryProducts] = useState([]);

  const [errors, setErrors] = useState({})

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    }
    else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!mobile || mobile.length === 0) {
      newErrors.phone = 'Phone Number is required';
    } else if (mobile.length > 11 || mobile.length < 7) {
      newErrors.phone = 'Invalid Phone Number length';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // calculating shipping charges & creating user as guest
  const onCalCulateCharge = async () => {
    // e.preventDefault();

    if (validateForm()) {
      const alProduct = JSON.parse(localStorage.getItem("items"));

      const ProductIds = alProduct.map((obj) => obj.id).join(",");
      const Price = alProduct
        .map((obj) => (parseFloat(obj.price) * parseFloat(obj.qty)).toFixed(2))
        .join(",");

      let ShippingAddress;
      let BillingAddress;
      let PostalCode;
      if (same_address == true) {
        BillingAddress = {
          address: billing_address,
          country: billing_country,
          state: billing_state,
          city: billing_city,
          postal_code: billing_code,
        };
        PostalCode = billing_code;

        ShippingAddress = {
          address: billing_address,
          country: billing_country,
          state: billing_state,
          city: billing_city,
          postal_code: billing_code,
        };
      } else {
        BillingAddress = {
          address: billing_address,
          country: billing_country,
          state: billing_state,
          city: billing_city,
          postal_code: billing_code,
        };

        ShippingAddress = {
          address: shipping_address,
          country: shipping_country,
          state: shipping_state,
          city: shipping_city,
          postal_code: shipping_code,
        };

        PostalCode = shipping_code;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("product_id", ProductIds);
      formData.append("price", Price);
      formData.append("postal_code", PostalCode);

      try {
        let hasNA, allNA;

        await axios({
          method: "post",
          url: `${config.apiUrl}/calculateShippingCost`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {

          hasNA = res.data?.products.some(
            (item) => item.shipping_cost === "N/A"
          );
          
          allNA = res.data?.products.every(
            (item) => item.shipping_cost === "N/A"
          );
          if (allNA) {
            setNotDelivering(true);
          } else if (hasNA && res.data?.products?.length > 1) {
            const NaProduct = res.data?.products
              .filter((item) => item.shipping_cost === "N/A")
              .map((item) => item.product_id);
            const updatedArray = cartItems.map((item) => ({
              ...item,
              delivery: NaProduct?.includes(item.id) ? 0 : 1,
            }));
            setDeliveryProducts(updatedArray);
            setDeliveryOpen(true);
          } else if (hasNA) {
            setNotDelivering(true);
          } else {
            setNotDelivering(false);
            const items = alProduct.map((item) => ({
              ...item,
              zip_status: 1,
            }));
            setCalculateStatus(true);
            localStorage.setItem("items", JSON.stringify(items));
            setPayment(true);
          }
          setShippingFees(res.data.shipping_cost);
          setCongestion(res.data.congestion);
          localStorage.setItem("congestion", res.data.congestion);
          localStorage.setItem("ship", res.data.shipping_cost);

          const userData = {
            shipping_code: same_address == true ? billing_code : shipping_code,
            billing_code: billing_code,
            billing_state: billing_state,
            shipping_state: same_address == true ? billing_state : shipping_state,
            billing_city: billing_city,
            shipping_city: same_address == true ? billing_city : shipping_city,
            billing_address: billing_address,
            shipping_address:
              same_address == true ? billing_address : shipping_address,
          };

          setshippingCode(userData?.shipping_code)
          setshippingstate(userData?.shipping_state)
          setshippingCity(userData?.shipping_city)
          setShippingAddress(userData?.shipping_address)

          localStorage.setItem("auth_users", JSON.stringify(userData));

        });

        // if user is not logged in
        if (!localStorage.getItem("auth_id") && !localStorage.getItem("auth_name")) {
          if (!allNA || !hasNA)
            await onGuestRegister()
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    }

  };

  const onGuestRegister = async () => {

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("type", 1);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/auth/signup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {

        if (response.data.message == "You are our permanent customer. Please login to continue.") {
          setCalculateStatus(false);

          notification.open({
            message: (
              <div className="d-flex">
                <div className="pr-20">
                  <i
                    className="fa fa-exclamation-circle text-danger"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
                <div>You are our permanent customer.</div>
              </div>
            ),
            description: "Please login to continue.",
          });

          navigate(`${config.appUrl}users/login`)

          // localStorage.setItem("auth_id", response.data.user_id.id);
          // localStorage.setItem("auth_name", response.data.user_id.name);
          // localStorage.setItem("auth_type", 0);
        } else if (response.data.user_id) {
          localStorage.setItem("auth_id", response.data.user_id.id);
          localStorage.setItem("auth_name", response.data.user_id.name);
          localStorage.setItem("auth_type", 'guest');
          window.location.reload()
        }

      });
    } catch (err) {
      console.log(err);
    }
  }

  // ===========================

  // Strip Payment Integration
  const [open, setOpen] = useState(false);

  const stripePayment = loadStripe(config.stripKey);

  useEffect(() => {
    if (localStorage.getItem("auth_users")) {
      const savedData = JSON.parse(localStorage.getItem("auth_users"));

      const isSame =
        savedData?.billing_code ==
        savedData?.shipping_code;
      setSameAddress(isSame);

      if (isSame == true) {
        setBillingCode(savedData?.billing_code);
        setshippingCode(savedData?.billing_code);

        setBillingAddress(savedData?.billing_address);
        setShippingAddress(savedData?.shipping_address);
        setBillingstate(savedData?.billing_state);
        setshippingstate(savedData?.billing_state);
        setBillingCity(savedData?.billing_city);
        setshippingCity(savedData?.billing_city);

      } else {
        setBillingCode(savedData?.billing_code);
        setshippingCode(savedData?.shipping_code);
        setBillingCity(savedData?.billing_city);
        setshippingCity(savedData?.shipping_city);
        setBillingstate(savedData?.billing_state);
        setshippingstate(savedData?.shipping_state);
        setBillingAddress(savedData?.billing_address);
        setShippingAddress(savedData?.shipping_address);
      }
    }
  }, []);


  const isNonNegotiable = cartItems?.some((item) => item.negotiate);

  const FinalCheckout = (e) => {
    swal({
      title: "Are you sure?",
      text: "You want to Placed an Order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const alProduct = JSON.parse(localStorage.getItem("items"));

        const ProductIds = alProduct.map((obj) => obj.id).join(",");
        const Widths = alProduct.map((obj) => obj.width).join(",");
        const Heights = alProduct.map((obj) => obj.height).join(",");
        const Lengths = alProduct.map((obj) => obj?.length).join(",");
        const Price = alProduct
          .map((obj) =>
            (
              (parseFloat(obj.price) + (parseFloat(obj.price) * 20) / 100) *
              parseFloat(obj.qty)
            ).toFixed(2)
          )
          .join(",");
        const vatFree = alProduct
          .map((obj) =>
            (parseFloat(obj.price) * parseFloat(obj.qty)).toFixed(2)
          )
          .join(",");
        const Qty = alProduct.map((obj) => obj?.qty).join(",");

        let ShippingAddress;
        let BillingAddress;
        let PostalCode;
        if (same_address == true) {
          BillingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };
          PostalCode = billing_code;

          ShippingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };
        } else {
          BillingAddress = {
            address: billing_address,
            country: billing_country,
            state: billing_state,
            city: billing_city,
            postal_code: billing_code,
          };

          ShippingAddress = {
            address: shipping_address,
            country: shipping_country,
            state: shipping_state,
            city: shipping_city,
            postal_code: shipping_code,
          };

          PostalCode = shipping_code;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append(
          "order_id",
          localStorage.getItem("order_id") != null
            ? localStorage.getItem("order_id")
            : 0
        );

        formData.append("user_id", localStorage.getItem("auth_id"));
        formData.append("product_id", ProductIds);
        formData.append("width", Widths);
        formData.append("height", Heights);
        formData.append("length", Lengths);
        formData.append("price", Price);
        formData.append("vat_free", vatFree);
        formData.append("qty", Qty);
        formData.append("postal_code", PostalCode);
        formData.append("payment_type", method == 3 ? "stripe" : "bank");
        formData.append("payment_receipt", payment_receipt);
        formData.append("charity_charges", charityApplied ? 1 : 0);
        formData.append("discountApplied", discountApplied?.toFixed(2) || 0);
        formData.append("shipping_address", JSON.stringify(ShippingAddress));
        formData.append("billing_address", JSON.stringify(BillingAddress));

        try {
          await axios({
            method: "post",
            url: `${config.apiUrl}/partial_order`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(async (res) => {
            localStorage.setItem("order_id", res.data.order_id);

            const userData = {
              shipping_code: PostalCode,
              billing_code: PostalCode,
              billing_state: billing_state,
              shipping_state:
                same_address == true ? billing_state : shipping_state,
              billing_city: billing_city,
              shipping_city:
                same_address == true ? billing_city : shipping_city,
              address:
                same_address == true ? billing_address : shipping_address,
            };

            localStorage.setItem("auth_users", JSON.stringify(userData));
            setPayment(true);

            // method = 3 means we are continuing with stripe payment
            if (method == 3) {
              const stripe = await stripePayment;
              // Create a new checkout session and redirect
              const formDatas = new FormData();
              formDatas.append("order_id", localStorage.getItem("order_id"));
              formDatas.append("amount", parseFloat(MainTotal).toFixed(2));
              
              const response = await axios({
                method: "post",
                url: `${config.apiUrl}/createCheckoutSession`,
                data: formDatas,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              });

              const session = await response?.data;

              setLoading(false)

              // Redirect to Stripe Checkout
              const result = await stripe.redirectToCheckout({
                sessionId: session.id,
              });

              if (result.error) {
                // Handle error here
                swal({
                  icon: "error",
                  title: "Payment Failed",
                  text: result.error.message,
                  icon: "error",
                });
              }
            }
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false)
        }
      }
    });
  };

  const onRemoveNonDeliver = () => {
    const filteredArray = cartItems
      .filter(
        (item) =>
          !deliveryProducts.some((dp) => dp.id === item.id && dp.delivery === 0)
      )
      .map((item) => ({ ...item, zip_status: 1 }));

    localStorage.setItem("items", JSON.stringify(filteredArray));
    setCartItems(filteredArray);
    setCalculateStatus(true);
    setDeliveryOpen(false);
    setPayment(true);

    const totalPrice = filteredArray
      ?.filter((obj) => obj.negotiate == false)
      .flat()
      .reduce((total, item) => {
        return total + parseFloat(item.price) * item.qty;
      }, 0);
    setSubTotalPrice(totalPrice);

    const VatPrice =
      congestion == 1 ? "15.00" : (parseFloat(totalPrice) * 20) / 100;
    setVatPrice(VatPrice);

    const charity = charityApplied ? charityCharges : 0;

    setMainTotal(
      parseFloat(totalPrice) + parseFloat(VatPrice) + parseFloat(shippingfess) + charity - discountApplied
    );
  };

  return (
    <div>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="ps-checkout ps-section--shopping pt-60">
            <div className="container">
              <div
                className="ps-section__header "
                style={{ paddingBottom: "30px" }}
              >
                <h1 style={{ fontSize: "30px", textAlign: "left" }}>
                  Checkout Information
                </h1>
              </div>

              <div className="ps-section__content">
                <div className="ps-form--checkout">
                  <div className="ps-form__content">

                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        {/* custome-checkout */}

                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 ">

                          <div className="ps-form__billing-info mt-20">
                            <div className="d-flex justify-content-between">
                              <h3 className="ps-form__heading">
                                Contact information
                              </h3>
                              {
                                (
                                  !localStorage.getItem("auth_id") &&
                                  !localStorage.getItem("auth_name")) ?
                                  <div>
                                    Already User? <Link to={`${config.appUrl}users/login`} style={{ fontWeight: 'bold' }}>Login here</Link>
                                  </div> : null
                              }
                            </div>
                            <div className="row">
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Full Name *"
                                    value={name}
                                    required
                                    disabled={localStorage.getItem("auth_id")
                                      && localStorage.getItem("auth_name")}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Email Id *"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    disabled={localStorage.getItem("auth_id")
                                      && localStorage.getItem("auth_name")}
                                  />
                                  {
                                    errors.email && <span className='text-danger'>{errors.email}</span>
                                  }
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Mobile No. *"}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    required
                                    disabled={localStorage.getItem("auth_id")
                                      && localStorage.getItem("auth_name")}
                                  />
                                  {
                                    errors.phone && <span className='text-danger'>{errors.phone}</span>
                                  }
                                </div>
                              </div>
                            </div>

                            <h3 className="ps-form__heading mt-20">
                              Billing address
                            </h3>

                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    value={billing_country}
                                    onChange={(e) =>
                                      setBillingCountry(e.target.value)
                                    }
                                    required
                                  >
                                    <option value="">Select Country *</option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    id="mySelect"
                                    onChange={onChangeState}
                                    required
                                    disabled={
                                      localStorage.getItem("auth_users") != null
                                    }
                                  >
                                    <option value="">Select State *</option>
                                    {state_option.map((obj, i) => {
                                      return (
                                        <option
                                          value={obj.id}
                                          id={obj.name}
                                          key={i}
                                          selected={billing_state == obj.name}
                                        >
                                          {obj.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {billing_state != null &&
                                    billing_state?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * State is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    required
                                    onChange={(e) =>
                                      setBillingCity(e.target.value)
                                    }
                                    disabled={
                                      localStorage.getItem("auth_users") != null
                                    }
                                  >
                                    {localStorage.getItem("auth_users") !=
                                      null ? (
                                      <option value="">{billing_city}</option>
                                    ) : null}
                                    <option value="">Select City *</option>

                                    {city_option.map((obj, i) => {
                                      return (
                                        <option
                                          value={obj.name}
                                          key={i}
                                          selected={billing_city == obj.name}
                                        >
                                          {obj.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {billing_city != null &&
                                    billing_city?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * City is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="postalCode"
                                    placeholder="Postal Code *"
                                    value={billing_code}
                                    onChange={(e) => {
                                      setBillingCode(
                                        e.target.value.replace(/\s/g, "")
                                      );
                                    }}
                                    required
                                    disabled={calculated_status == true}
                                  />
                                  {billing_code != null &&
                                    billing_code?.length == 0 ? (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      * Postal Code is Required
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <Input
                                className="form-control"
                                type="text"
                                placeholder="Address *"
                                value={billing_address}
                                onChange={(e) =>
                                  setBillingAddress(e.target.value)
                                }
                                required
                                disabled={calculated_status == true}
                              />
                              {billing_address != null &&
                                billing_address?.length == 0 ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  * Address is Required
                                </span>
                              ) : null}
                            </div>
                            <div className="form-group mt-20">
                              <div className="">
                                <Switch
                                  checked={same_address}
                                  onChange={(e) => setSameAddress(e)}
                                />
                                <label
                                  htmlFor="save-information"
                                  className="ml-20"
                                >
                                  Same Address in Shipping Details
                                </label>
                              </div>
                            </div>

                            {/* Shipping Address Details */}

                            {same_address == false ? (
                              <>
                                <h3 className="ps-form__heading mt-20">
                                  Shipping Address
                                </h3>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        value={shipping_country}
                                        onChange={(e) =>
                                          setshippingCountry(e.target.value)
                                        }
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        <option value="">
                                          Select Country *
                                        </option>
                                        <option value="United Kingdom">
                                          United Kingdom
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        onChange={onChangeShippingState}
                                        required
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        <option value="">Select State *</option>
                                        {state_option.map((obj, i) => {
                                          return (
                                            <option
                                              value={obj.id}
                                              id={obj.name}
                                              key={i}
                                              selected={
                                                shipping_state == obj.name
                                              }
                                            >
                                              {obj.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {shipping_state != null &&
                                        shipping_state?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * State is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        required
                                        onChange={(e) => {
                                          setshippingCity(e.target.value);
                                        }}
                                        disabled={
                                          localStorage.getItem("auth_users") !=
                                          null
                                        }
                                      >
                                        {localStorage.getItem("auth_users") !=
                                          null ? (
                                          <option value="">
                                            {shipping_city}
                                          </option>
                                        ) : null}
                                        <option value="">Select City *</option>
                                        {city_ship_option.map((obj, i) => {
                                          return (
                                            <option value={obj.name} key={i}>
                                              {obj.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {shipping_city != null &&
                                        shipping_city?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * City is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <Input
                                        className="form-control"
                                        type="postalCode"
                                        placeholder="Postal Code *"
                                        value={shipping_code}
                                        onChange={(e) => {
                                          setshippingCode(
                                            e.target.value.replace(/\s/g, "")
                                          );
                                        }}
                                        disabled={calculated_status == true}
                                      />
                                      {shipping_code != null &&
                                        shipping_code?.length == 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: "12px" }}
                                        >
                                          * Postal Code is Required
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Address *"
                                    value={shipping_address}
                                    onChange={(e) =>
                                      setShippingAddress(e.target.value)
                                    }
                                    disabled={calculated_status == true}
                                  />
                                </div>
                                {shipping_address != null &&
                                  shipping_address?.length == 0 ? (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                  >
                                    * Address is Required
                                  </span>
                                ) : null}
                              </>
                            ) : null}

                            <div className="ps-form__submit mt-5">
                              <Link to="/shopping-cart"
                                style={{ fontWeight: 500 }}
                                className="alert bg-light text-decoration-none">
                                <i className="icon-arrow-left mr-3"></i>
                                Return to Shopping Cart
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* custome-order */}
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  ps-block--checkout-order ">
                          <div className="ps-form__orders">
                            <h3>Your order</h3>

                            <div className="ps-block--checkout-order">
                              <div className="ps-block__content">
                                <figure className="mb-20">
                                  <figcaption>
                                    <strong>Product</strong>
                                    <strong>total</strong>
                                  </figcaption>
                                </figure>
                                {cartItems?.map((item, i) => {
                                  const total =
                                    parseFloat(item.price) *
                                    parseFloat(item.qty);
                                  return (
                                    <figure
                                      className={`ps-block__items pb-0 ${item?.negotiate
                                        ? "bg-nego-light-blue"
                                        : ""
                                        }`}
                                    >
                                      <div className="d-flex justify-space-between">
                                        <a className="col-9 ">
                                          <strong>
                                            {item.name}
                                            <span>x {item.qty}</span>
                                            {item.type == "Linear" ? (
                                              <p className="varition-size">
                                                H : {item.height}mm , W :{" "}
                                                {item.width}mm , L :{" "}
                                                {item.length}m{" "}
                                              </p>
                                            ) : null}
                                          </strong>
                                        </a>
                                        <a className="col-2 justify-content-center">
                                          <small>£{total?.toFixed(2)}</small>
                                        </a>
                                      </div>
                                      {item.negotiate ? (
                                        <p
                                          className="text-danger "
                                          style={{ fontSize: "11px" }}
                                        >
                                          * Non Negotiable Product as cart is
                                          Already Negotiated
                                        </p>
                                      ) : null}
                                    </figure>
                                  );
                                })}

                                {
                                  discountApplied ?
                                    <div className="alert alert-success d-flex justify-content-between mt-4">
                                      <span>10% Discount Applied</span>
                                      <strike className="text-dark"> £ {(subTotal_price + discountApplied)?.toFixed(2)} </strike>
                                    </div>
                                    : null
                                }

                                <div className={`alert alert-${charityApplied ? 'primary' : 'light'} border d-flex border-primary mt-4`}>
                                  <div >
                                    <input type="checkbox"
                                      checked={charityApplied}
                                      onClick={(e) => {
                                        setCharityApplied(e.target.checked)
                                        if (e.target.checked) {
                                          localStorage.setItem('charity', true)
                                          setMainTotal(prev => (prev + charityCharges))
                                        }
                                        else {
                                          localStorage.removeItem('charity')
                                          setMainTotal(prev => (prev - charityCharges))
                                        }
                                      }}
                                      id="charity"
                                    />
                                  </div>
                                  <label htmlFor="charity" style={{ cursor: 'pointer' }} className="mb-0 text-dark w-100 ml-10">
                                    Donate £1 to plant a tree
                                  </label>
                                </div>

                                <figure className="mt-20">
                                  <figcaption className="checkout-nego py-2 px-4">
                                    <strong>
                                      {isNegotiate
                                        ? "Negotiate Cart"
                                        : "Subtotal"}
                                    </strong>
                                    <small className="text-right">
                                      <strong>
                                        £{subTotal_price?.toFixed(2)}
                                      </strong>
                                    </small>
                                  </figcaption>

                                  {isNegotiate && (
                                    <>
                                      <figcaption>
                                        <strong>Discount </strong>
                                        <small className="text-right">
                                          <strong>
                                            <span
                                              style={{ fontSize: "11.5px" }}
                                            >
                                              {" "}
                                              (£
                                              {parseFloat(
                                                negotiated_price
                                              )?.toFixed(2)}
                                              )
                                            </span>{" "}
                                            £
                                            {(
                                              parseFloat(subTotal_price) -
                                              parseFloat(negotiated_price)
                                            )?.toFixed(2)}
                                          </strong>
                                        </small>
                                      </figcaption>
                                      {isNonNegotiable ? (
                                        <>
                                          <figcaption className="checkout-nonnego">
                                            <strong>Non Negotiate Cart</strong>
                                            <small className="text-right">
                                              <strong>
                                                £
                                                {parseFloat(
                                                  nonNegotiatedtotal
                                                )?.toFixed(2)}
                                              </strong>
                                            </small>
                                          </figcaption>
                                          <figcaption className="checkout-total">
                                            <strong>Total </strong>
                                            <small className="text-right">
                                              <strong>
                                                £
                                                {(
                                                  parseFloat(
                                                    nonNegotiatedtotal
                                                  ) +
                                                  parseFloat(negotiated_price)
                                                )?.toFixed(2)}
                                              </strong>
                                            </small>
                                          </figcaption>
                                        </>
                                      ) : (
                                        <figcaption className="checkout-total">
                                          <strong>Total </strong>
                                          <small className="text-right">
                                            <strong>
                                              £
                                              {(
                                                parseFloat(nonNegotiatedtotal) +
                                                parseFloat(negotiated_price)
                                              )?.toFixed(2)}
                                            </strong>
                                          </small>
                                        </figcaption>
                                      )}
                                    </>
                                  )}
                                </figure>
                                {payment || calculated_status ? (
                                  <>
                                    <figure>
                                      <figcaption>
                                        <strong>VAT</strong>
                                        <small>
                                          <strong>
                                            £
                                            {congestion == 1
                                              ? "0.00"
                                              : vatPrice.toFixed(2)}
                                          </strong>
                                        </small>
                                      </figcaption>
                                      {congestion == 1 && (
                                        <figcaption>
                                          <strong>Congestion Charge</strong>
                                          <small>
                                            <strong>£15.00</strong>
                                          </small>
                                        </figcaption>
                                      )}
                                    </figure>
                                    <figure>
                                      <figcaption>
                                        <strong>Shipping Fee</strong>
                                        <small>
                                          <strong>
                                            £
                                            {parseFloat(shippingfess).toFixed(
                                              2
                                            )}
                                          </strong>
                                        </small>
                                      </figcaption>
                                    </figure>
                                    {
                                      charityApplied ?
                                        <figure>
                                          <figcaption>
                                            <strong>Charity Charges </strong>
                                            <small>
                                              <strong>
                                                £
                                                {parseFloat(charityCharges).toFixed(2)}
                                              </strong>
                                            </small>
                                          </figcaption>
                                        </figure> : null
                                    }
                                    <figure className="ps-block__total mb-0 pb-0 border-bottom-none">
                                      <h3>
                                        Grand Total
                                        <strong>£{(MainTotal).toFixed(2)}</strong>
                                      </h3>
                                    </figure>
                                  </>
                                ) : null}
                              </div>
                            </div>

                            {calculated_status == false ? (
                              <div className="ps-block__footer">
                                <button onClick={() => {
                                  if (billing_address) {
                                    setPolicy(true)
                                  }
                                }} className="ps-btn bg-blue color-white w-100">
                                  Calculate Shipping & Payment
                                </button>
                              </div>
                            ) : null}
                            {noDelivering === false ? (
                              <>
                                {calculated_status ? (
                                  <div className="mt-20">
                                    <h4 className="mb-15">Payment Methods</h4>
                                    <div className="ps-block--payment-method  ">
                                      {/* d-flex justify-content-around */}
                                      <div className="ps-block__header">
                                        <Radio.Group
                                          onChange={(e) =>
                                            handleChangeMethod(e)
                                          }
                                          value={method}
                                        >
                                          <Radio value={3}>
                                            Credit / Debit Card
                                          </Radio>
                                        </Radio.Group>
                                      </div>

                                    </div>
                                    <div className="ps-block__footer">
                                      {method == 3 ? (
                                        <div className="">
                                          <button
                                            type="button"
                                            className="ps-btn bg-blue color-white text-center w-100 mb-2 h-auto px-3"
                                            onClick={FinalCheckout}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(255, 182, 0)",
                                                fontWeight: "650",
                                              }}
                                            >
                                              {" "}
                                              £{MainTotal.toFixed(2)}
                                            </span>{" "}
                                            - Pay with Credit / Debit Card
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className="text-center text-danger mt-3">
                                <Alert
                                  message="We're sorry! Currently, we can’t deliver
                                      to this pincode. Please choose another!"
                                  type="error"
                                  className="text-danger"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>

      <Modal
        open={open}
        title="Stripe Payment"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <Elements stripe={stripePayment}>
          <CheckoutForm
            MainTotal={MainTotal}
            discountApplied={discountApplied}
            charityApplied={charityApplied} />
        </Elements>
      </Modal>

      <Modal
        open={deliverOpen}
        title="Delivery Status"
        width={850}
        centered
        onOk={() => setDeliveryOpen(false)}
        onCancel={() => setDeliveryOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <div>
          <table role="table" className="table-striped table">
            <thead className="">
              <tr role="row">
                <th>Id</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>VAT</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {deliveryProducts?.map((obj, i) => {
                const vatCal = parseFloat(obj.price) * 0.2;
                return (
                  <tr
                    role="row"
                    className={obj.delivery == 0 ? "red-mark" : ""}
                    key={i}
                  >
                    <td className="ant-table-cell">
                      {obj.delivery == 0 ? (
                        <Tooltip
                          title="This product can’t be delivered to the provided pincode. We’ll remove those from your cart, and you can proceed with the remaining order"
                          placement="bottom"
                          color={"#fc5363"}
                        >
                          <a style={{ paddingRight: "3px" }}>
                            <i className="icon-question-circle "></i>
                          </a>
                        </Tooltip>
                      ) : null}
                      {obj.id}
                    </td>
                    <td>{obj.name}</td>
                    <td>{obj.qty}</td>
                    <td>£{parseFloat(parseFloat(obj.price))?.toFixed(2)}</td>
                    <td>{parseFloat(vatCal).toFixed(2)}</td>
                    <td>
                      £{(parseFloat(obj.price) + parseFloat(vatCal)).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="">
            Note : One or more of the items marked in red can’t be delivered to
            the provided pincode. We’ll remove those from your cart, and you can
            proceed with the remaining order. Thank you for your understanding!
          </div>
          <div className="mt-3 text-right">
            <a
              className="ps-btn bg-blue color-white text-center  mb-2"
              onClick={onRemoveNonDeliver}
            >
              Remove and Continue with Payment
            </a>
          </div>
        </div>
      </Modal>

      {/* Policy Rules */}
      <Modal
        open={policy}
        title="Rules of Refund"
        centered
        onOk={() => setPolicy(false)}
        onCancel={() => setPolicy(false)}
        footer={<div className="footer-none"></div>}
      >
        {/* <div className="mb-5 py-2 head_line px-3" style={{ fontSize: "15px" }}>
                We are closed, and orders will not be processed until we return on 2nd January 2025.
              </div> */}

        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>

        <div className="text-center mb-20">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setPolicy(false)}
          >
            Back
          </a>

          <a onClick={() => {
            setPolicy(false)
            onCalCulateCharge()
          }} className="btn-blue p-4 ml-10">
            Okay Continue..
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default Checkout;
