const config = {
  appUrl: "/",

  // Demo
  // apiUrl: "https://demo.w-hanson.co.uk/backend/api/v2",
  // imageUrl: "https://demo.w-hanson.co.uk/backend/storage/app/public",
  // websiteUrl: "https://demo.w-hanson.co.uk",
  // stripKey: "pk_test_51O9iUSSAu4SQbYbwKYY8lV7aGKR1h7xAZKxjBY8NAK3CtawTmoS5xb5CrN9Vxp5wbaectWNviMVnANiCae1ANvgg00VwGp0z2H", 

  // Live
  apiUrl: "https://w-hanson.co.uk/backend/api/v2",
  imageUrl: "https://w-hanson.co.uk/backend/storage/app/public",
  websiteUrl: "https://w-hanson.co.uk",
  stripKey: "pk_live_51LBAz6JaXtAdPEaby8WP5UjKwPG8CKHF0kCCCfexzixAXhGUVhJa1iUclgI5wJtQQIKFk9DaAo445tuxYQtjJys300mWgRMOWv",


  // ==========================
  // stripKey: "pk_test_51Ql3WyBLuFgOk5DUhN90wPBzFWkvQVlcnRX0j4zohfq1Z9Pm7OTF7DGzJqzS5ivObSNLxqQGTyPznOZUPuZ253RH008QvjN0B0", 
  // for bank option 
};

export default config;