// JobListings Component
import React, { useState } from "react";
import "./career.css";

const JobListings = ({ formRef, setPosition }) => {
    const jobList = [
        {
            id: 1,
            title: 'Sawmill Machine Operator',
            location: 'Southall Depot',
            jobTypes: ['Full-time', 'Permanent'],
            salary: '£12.21 – 13.50 per hour',
            heading: "The successful candidate must have:", 
            description:
                'W Hanson have an exciting opportunity for an enthusiastic, reliable, and hardworking Machinist/ Sawmill Operator at out Southall depot  This is a fast-paced working environment working within a well-established team.We are looking for candidates who have relevant experience within timber industry.  Experience is preferable but not essential – any experience working in a mill, or a timber yard is highly beneficial. If you have any experience as a state moulder operator or a machinist, we would be interested in speaking to you.  Candidates who have experience and qualifications in wood-machining, specifically operating and setting up high speed wood moulders. Company specific training will be provided to enhance gaps in skills, and candidates who can demonstrate a genuine thirst to learn will achieve their potential',

            duties: [
                "Setting and operating traditional wood working machines to produce batches of components required (from solid wood and from panel products) to a high-quality finish",
                "Setting up and operating high speed wood moulder, (Weinig, Leadermac, wadkin etc.).",
                "Ability to use various machinery such as, Morticer, Cross Cut Saw, Spindle Moulder, Planer, Thicknesser and Table Saw.",
                "Machine timber to order",
                "Undertake routine operator led maintenance on machinery daily",
                "Follow all safety protocols and procedures to maintain a safe working environment.",
            ],
            qualifications: [
                "Experience with wood machinery and technical skills in wood working machinery",
                "An eye for detail",
                "Willingness to learn and develop and an ability or desire to teach others",
                "A positive, can-do attitude with basic problem-solving abilities",
            ],
            benefits: [
                'Company pension',
                'Employee discount',
                'Free parking',
                'On-site parking',
                'Store discount',
            ],
            expectedHours: "45 per week",
            schedule: ['Day shift', 'Monday to Friday', 'Weekend availability', 'On-site'],
            experience: ['Woodworking: 1 year (preferred)'],
            // workLocation: 'On-site',
        },
        {
            id: 2,
            title: 'Yard Worker',
            location: 'W.Hanson (Harrow) Ltd – Southall',
            jobTypes: ['Full-time', 'Permanent'],
            salary: '£28,570 per year',
            heading: "Qualifications:", 
            description:
                'W Hanson are seeking a dedicated Yard Worker to join our team. The ideal candidate will be responsible for handling various tasks within our yard, ensuring the smooth operation of daily activities.',
            duties: [
                "Operate forklifts to move materials around the yard",
                "Assist in building and organising materials",
                "Utilise mechanical knowledge to perform basic maintenance tasks",
                "Engage in heavy lifting activities as required",
                "Manage warehouse inventory using a warehouse management system",
                "Handle shipping and receiving duties efficiently",
                "Safely handle materials within the yard",
                "Load and unload materials from vehicles",
            ],
            qualifications: [
                "Previous experience in a warehouse environment is advantageous",
                "Proficiency in operating forklifts is essential",
                "Strong mechanical knowledge for basic maintenance tasks",
                "Ability to lift heavy objects safely",
                "Familiarity with warehouse management systems is beneficial",
                "Experience in shipping, receiving, and materials handling is preferred",
            ],
            benefits: ['Company pension', 'Free parking', 'On-site parking'],
            schedule: ['Monday to Friday', 'Weekend availability'],
        },
        {
            id: 3,
            title: 'Yardman',
            location: 'W.Hanson (Harrow) Ltd – Harrow HA1 3QL',
            jobTypes: ['Full-time', 'Permanent'],
            salary: '£28,570 per year',
            heading: "Qualifications", 
            description:
                'W Hanson are seeking a dedicated Yard Worker to join our team. The ideal candidate will be responsible for handling various tasks within our yard, ensuring the smooth operation of daily activities.',

            duties: [
                "Operate forklifts to move materials around the yard",
                "Assist in building and organising materials",
                "Utilise mechanical knowledge to perform basic maintenance tasks",
                "Engage in heavy lifting activities as required",
                "Manage warehouse inventory using a warehouse management system",
                "Handle shipping and receiving duties efficiently",
                "Safely handle materials within the yard",
                "Load and unload materials from vehicles",
            ],
            qualifications: [
                "Previous experience in a warehouse environment is advantageous",
                "Proficiency in operating forklifts is essential",
                "Strong mechanical knowledge for basic maintenance tasks",
                "Ability to lift heavy objects safely",
                "Familiarity with warehouse management systems is beneficial",
                "Experience in shipping, receiving, and materials handling is preferred",
            ],
            benefits: ['Company pension', 'Free parking', 'On-site parking'],
            schedule: ['Monday to Friday', 'Weekend availability'],
            workLocation: 'On-site',
        },
        {
            id: 4,
            title: 'HGV Class 2 HIAB Driver',
            location: 'W.Hanson (Harrow) Ltd – Harrow',
            jobTypes: ['Full-time'],
            salary: '£35,000.00 – £40,000.00 per year',
            heading: "Candidate requirements:", 
            description:
                'W.Hanson (Harrow) Ltd is a small business in Harrow. We are fast-paced and engaging.  W Hanson (building Supplies) are looking for a HGV Class 2 Driver, with at least 1 year driving experience. HIAB Certificate essential.  The role will involve delivering fantastic customer service by building sound working relationships with regular clients and recognizing their requirements and service expectations.',

            qualifications: [
                "Must hold a valid HIAB Licence",
                "Must hold a valid Digital Tacho Card",
                "Must have Full CPC with valid DQC (digital qualification card)",
                "Experience in Customer Service",
                "No more than 6 points on your licence",
                "Dealing with customers",
                "Able to load and unload heavy goods. A flexible and can-do attitude",
                "Ensure vehicle is maintained to the required standard and any defects are reported.",
            ],
            benefits: ['Company pension', 'Employee discount', 'Free parking', 'On-site parking'],
            schedule: ['10 hour shift', 'Monday to Friday', 'Weekend availability'],
            experience: ['HIAB: 1 year (required)', 'Class 2: 1 year (required)'],
            workLocation: 'On-site',
        },
    ];

    return (
        <div className="job-listings">
            {jobList.map((job) => (
                <JobCard key={job.id} job={job} formRef={formRef} setPosition={setPosition} />
            ))}
        </div>
    );
};

// JobCard Component
const JobCard = ({ job, formRef, setPosition }) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const onApply = () => {
        setPosition(job.title) 
        formRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className="job-card">
            <div className="job-card-header ">
                <h2 className="job-title">{job.title}</h2>
                <p className="job-location">{job.location}</p>
                <span className="salary">{job.salary}</span>
            </div>
            <div className="job-card-body">
                {
                    !showDetails ?
                        <p className="job-description">
                            {job.description.substring(0, 250)}...
                        </p> : null
                }

                {showDetails && (
                    <div className="full-description mt-4">
                        <h2>Job Description</h2>
                        <p>{job.description}</p>

                        {job.duties && (
                            <div className="job-experience">
                                <h4>The role will encompass the following duties:</h4>
                                <ul>
                                    {job.duties.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {job.qualifications && (
                            <div className="job-experience">
                                <h4>{job.heading}</h4>
                                <ul>
                                    {job.qualifications.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {job.salary && (
                            <div className="job-work-location ">
                                <h4>Salary:</h4>
                                <p className="pl-5">{job.salary}</p>
                            </div>
                        )}

                        {job.jobTypes && (
                            <div className="job-experience">
                                <h4>Job Types:</h4>
                                <ul>
                                    {job.jobTypes.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {job.benefits && (
                            <div className="job-experience">
                                <h4>Benefits:</h4>
                                <ul>
                                    {job.benefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {job.schedule && (
                            <div className="job-experience">
                                <h4>Schedule:</h4>
                                <ul>
                                    {job.schedule.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {job.experience && (
                            <div className="job-experience">
                                <h4>Experience:</h4>
                                <ul>
                                    {job.experience.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {job.workLocation && (
                            <div className="job-work-location ">
                                <h4>Work Location:</h4>
                                <p className="pl-5">{job.workLocation}</p>
                            </div>
                        )}
                    </div>
                )}

                <div className="buttons">
                    <button className="ps-btn bg-blue py-3 px-5 color-white" onClick={toggleDetails}>
                        {showDetails ? 'Show Less' : 'Read More'}
                    </button>
                    <button className="ps-btn bg-success ml-20 py-3 px-5 color-white"
                        onClick={onApply}
                    >Apply</button>
                </div>
            </div>
        </div>
    );
};


export default JobListings;