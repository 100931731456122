import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import BreadCrumb from "../../components/elements/BreadCrumb";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import { Link, useNavigate } from "react-router-dom";
import { Input, notification } from "antd";
import axios from "axios";
import Loader from "../Loader";
import config from "../../config";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);


  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Login",
    },
  ];
  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show_password, setShowPassword] = useState(false);

  const onHandleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/auth/login`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }

      }).then((response) => {
        if (response.data.message == "Unauthorized") {
          // setError(true);
          notification.open({
            message: (
              <div className="d-flex">

                <div className="pr-20">
                  <i className="fa fa-exclamation-circle text-danger" style={{ fontSize: "20px" }} ></i>
                </div>

                <div>Opp! Something went wrong.</div>
              </div>
            ),
            description: "Please Enter Correct Credentials",
          });
          setLoading(false);
          return;
        }

        if (response.data.message != "User not found") {
          notification.open({
            message: (
              <div className="d-flex">
                <div className="pr-20">
                  <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                </div>
                <div>Welcome {response.data.user.name}</div>
              </div>
            ),
            description: "You are login successful!",
          });
          localStorage.setItem("auth_id", response.data.user.id);
          localStorage.setItem("auth_name", response.data.user.name);
          localStorage.setItem("auth_type", 0);

          if (localStorage.getItem("items") && JSON?.parse(localStorage.getItem("items"))?.length) {
            if (localStorage.getItem("curr_path"))
              navigate(`${config.appUrl}checkout`);
            else
              navigate(`${config.appUrl}shopping-cart`);
          } else
            navigate(`${config.appUrl}profile`);

        } else {
          setError(true);
          notification.open({
            message: (
              <div className="d-flex">
                <div className="pr-20">
                  <i
                    className="fa fa-exclamation-circle text-danger"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
                <div>Opp! Something went wrong.</div>
              </div>
            ),
            description: "Please Enter Correct Credentials",
          });
        }

        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>W-Hanson UK Users - Sign In Now</title>
        <meta name="description" content="Login to manage your details and preferences easily. Safe and convenient login process awaits!" />
      </Helmet>
      <PageContainer header={headers} footer={footer} title="Login">
        {isLoading && <Loader />}
        <div className="ps-page--my-account">
          <BreadCrumb breacrumb={breadCrumb} />

          <div className="mb-70 mt-70 bg-white">
            <div className="container">
              <form className="ps-form--account pt-0" onSubmit={onHandleLogin}>
                <div className="ps-tab active border-radius3 bd-login-page">
                  <div className="ps-form__content p-5 ">
                    <h2 className="pb-20 text-center">Log In Your Account</h2>
                    <div className="form-group form-forgot">
                      <Input
                        className="form-control"
                        type="email"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {error == true ? (
                        <span className="text-danger font-12">
                          Please Enter Correct Email
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group form-forgot ">
                      <div className="position-relative">
                        <Input
                          className="form-control"
                          type={show_password ? "text" : "password"}
                          placeholder="Password..."
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <div className="bd-eye-open">
                          <span onClick={() => setShowPassword(!show_password)}>
                            <i
                              className={
                                show_password ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                      {error == true ? (
                        <span className="text-danger font-12">
                          Please Enter Correct Password
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group pb-20 d-flex justify-space-between">
                      <div className="ps-checkbox">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="remember-me"
                          name="remember-me"
                        />
                        <label htmlFor="remember-me">Remember me</label>
                      </div>
                      <div>
                        <Link to="/users/forgot-password" style={{ fontSize: '12px', color: "black" }}>Forgot Password ?</Link>
                      </div>
                    </div>
                    <div className="form-group submit">
                      <button
                        type="submit"
                        className="ps-btn ps-btn--fullwidth bg-blue color-white"
                      >
                        Login
                      </button>
                    </div>
                    <div className="mt-20 text-center">
                      <p className="mb-0">Continue as
                        <Link to={`${config.appUrl}users/guest-checkout`}
                          className="color-blue"
                          style={{ fontWeight: "500" }}> Guest User?</Link></p>
                    </div>
                    <div className="mt-20 text-center">
                      <p className="mb-0">Dont have an account?</p>
                      <Link to="/users/registration" className="color-blue" style={{ fontWeight: "600" }}>Register Now</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default LoginPage;
