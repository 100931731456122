import React, { useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import userImg from "../../assets/static/img/avatar-place.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Purchase from "./Purchase";
import Panel from "./Panel";
import Address from "./Address";
import { notification } from "antd";
import { useEffect } from "react";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import config from "../../config";
import CartData from "./CartData";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/users/login");
    }

    document.title = "My Account - Discover Quality Metalwork at W-Hanson UK";
  }, []);

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  const accountLinks = [
    {
      text: "Dashboard",
      url: "/dashboard",
      icon: "icon-home",
      active: false,
      tab: 1,
    },
    {
      text: "Purchase History",
      url: "/account/notifications",
      icon: "icon-store",
      tab: 2,
    },
    {
      text: "Cart Data",
      url: "/account/notifications",
      icon: "icon-cart",
      tab: 5,
    },
    {
      text: "Address",
      url: "/account/invoices",
      icon: "icon-map-marker",
      tab: 3,
    },
    {
      text: "Account Information",
      url: "/account/user-information",
      icon: "icon-user",
      active: true,
      tab: 4,
    },
  ];

  const [verticalTab, setVerticalTab] = useState(1);

  const handleLogout = () => {
    swal({
      title: "Are you sure?",
      text: "You want to Logout your Account",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        localStorage.removeItem("auth_id");
        localStorage.removeItem("auth_name");
        notification.open({
          message: (
            <div className="d-flex">
              <div className="pr-20">
                <i
                  className="fa fa-exclamation-circle text-danger"
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
              <div>Thank You !</div>
            </div>
          ),
          description: "You Account has been logout Successful",
        });
        navigate("/");
      }
    });
  };

  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [states, setStates] = useState([]);
  const [phone, setPhone] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const searchParams = new URLSearchParams(useLocation().search);
  const tab = searchParams.get("tab");
  const type = searchParams.get("type");
  const [IsActive, setActive] = useState(false);

  useEffect(() => {
    onFectUserData();
    if (tab == "cart") {
      setVerticalTab(5);
    }
  }, []);

  const onFectUserData = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/users/${localStorage.getItem("auth_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setPhone(res?.data?.users[0]?.phone);
        setStates(res.data.states);
        setName(res.data.users[0]?.name);
        setEmail(res.data.users[0]?.email);
        setLoading(false);
        if (tab == "cart") {
          setActive(true);
          setTimeout(() => {
            setActive(false);
          }, 5000);
        }
        if (type == "chat") {
          const handleClick = () => {
            if (window.Tawk_API) {
              window.Tawk_API.toggle(); // Tawk.to chat widget toggle
            }
          };
          handleClick();
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onUpdateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("auth_id"));
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("old_password", old_password);
    formData.append("new_password", new_password);
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}/profile`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {

        localStorage.setItem("auth_name", name)
        
        notification.open({
          message: (
            <div className="d-flex">
              <div className="pr-20">
                <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
              </div>
              <div>Profile Updated Successfully</div>
            </div>
          ),
          description: "Please verify and log in to your account.",
        });
        
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <PageContainer header={headers} footer={footer} title="Register">
        <div className="ps-page--my-account">
          <div>
            <section className="ps-my-account ps-page--account">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 ">
                    <div className="ps-section__left">
                      <aside className="ps-widget--account-dashboard">
                        <div className="ps-widget__header">
                          <img src={userImg} />
                          <figure>
                            <figcaption>Welcome Back, <br /> {name}</figcaption>
                            <p>{email}</p>
                          </figure>
                        </div>
                        <div className="ps-widget__content">
                          <ul className="ps-list--user-links">
                            {accountLinks.map((link) => (
                              <li
                                key={link.text}
                                className={
                                  link.tab == verticalTab ? "active" : ""
                                }
                              >
                                <a onClick={() => setVerticalTab(link.tab)}>
                                  <i className={link.icon}></i>
                                  {link.text}
                                </a>
                              </li>
                            ))}
                            <li>
                              <a onClick={() => handleLogout()}>
                                <i className="icon-power-switch"></i>
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    {verticalTab == 1 ? (
                      <Panel verticalTab={verticalTab} />
                    ) : verticalTab == 2 ? (
                      <Purchase
                        verticalTab={verticalTab}
                        name={name}
                        email={email}
                        phone={phone}
                      />
                    ) : verticalTab == 3 ? (
                      <Address states={states} verticalTab={verticalTab} />
                    ) : verticalTab == 4 ? (
                      <div className="ps-page__content">
                        <form
                          className="ps-form--account-setting"
                          onSubmit={onUpdateProfile}
                        >
                          <div className="ps-form__header">
                            <h3>Account Information</h3>
                          </div>
                          <div className="ps-form__content ">
                            <div className="mb-4">
                              <div className="form-group">
                                <label>
                                  Full Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Full Name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 mb-3">
                                <div className="form-group">
                                  <label>
                                    Phone Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    value={phone}
                                    minLength={7}
                                    maxLength={15}
                                    onChange={(e) => setPhone(e.target.value)}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>
                                    Email Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Old Password</label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Enter Old Password"
                                    onChange={(e) =>
                                      setOldPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>New Password</label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Enter New Password"
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group submit mt-20">
                              <button className="ps-btn bg-blue color-white">
                                Update profile
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : verticalTab == 5 ? (
                      <CartData verticalTab={verticalTab} IsActive={IsActive} />
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Dashboard;
