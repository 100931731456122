import React, { useEffect, useRef, useState } from 'react'
import PageContainer from '../../components/layouts/PageContainer'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import Loader from '../Loader';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { Input, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const Otp = () => {

    // if (typeof window !== "undefined") {
    //     $(document).ready(function () {
    //         $('input').keyup(function () {
    //             if (this.value.length == $(this).attr("maxlength")) {
    //                 $(this).next().focus();
    //             }
    //         });
    //     });
    // }
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);


    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Login",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    const [opt1, setOtp1] = useState('')
    const [opt2, setOtp2] = useState('')
    const [opt3, setOtp3] = useState('')
    const [opt4, setOtp4] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    const [active, setActive] = useState(false)
    const [show_password, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [reset_password, setPassWordReset] = useState('')
    const navigate = useNavigate();

    const onHandleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = opt1 + opt2 + opt3 + opt4;
        if (localStorage.getItem('pass') == formData) {
            setActive(true)
            setLoading(false);
            setError(false)
            localStorage.removeItem('pass')
            notification.open({
                message: (
                    <div className="d-flex">
                        <div className="pr-20">
                            <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                        </div>
                        <div>OPT Verified Successfully</div>
                    </div>
                ),
                description: "Reset Your Password",
            });
        } else {
            setError(true)
            setLoading(false);
        }
    };

    const onHandleReset = async (e) => {
        e.preventDefault();
        if (password == reset_password) {
            setLoading(true);
            const formData = new FormData();
            formData.append("password", password);
            formData.append("user_id", localStorage.getItem('authid'));
            try {
                const response = await axios({
                    method: "post",
                    url: `${config.apiUrl}/reset_password`,
                    data: formData,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    notification.open({
                        message: (
                            <div className="d-flex">
                                <div className="pr-20">
                                    <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                                </div>
                                <div>Password Reseted Successfully</div>
                            </div>
                        ),
                        description: "Password is Reseted Successfully",
                    });
                    localStorage.removeItem('pass')
                    navigate("/users/login");
                    setLoading(false);
                });
            } catch (err) {
                setLoading(false);
            }
        } else {
            setError(true);
            notification.open({
                message: (
                    <div className="d-flex">
                        <div className="pr-20">
                            <i
                                className="fa fa-exclamation-circle text-danger"
                                style={{ fontSize: "20px" }}
                            ></i>
                        </div>
                        <div>Opp! Something went wrong.</div>
                    </div>
                ),
                description: "Please Enter Same Password",
            });
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('pass') && !localStorage.getItem('authid')) {
            navigate('/users/login')
        } else if (localStorage.getItem('pass') == undefined) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [])




    return (
        <PageContainer header={headers} footer={footer} title="Login">
            {isLoading && <Loader />}
            <div className="ps-page--my-account">
                <BreadCrumb breacrumb={breadCrumb} />
                <div className="mb-70 mt-70 bg-white">
                    <div className="container">
                        {active == false ? (
                            <form className="ps-form--account pt-0" onSubmit={onHandleLogin}>
                                <div className="ps-tab active border-radius3 bd-login-page">
                                    <div className="ps-form__content p-5 ">
                                        <h2 className="pb-20 text-center">OTP Verification</h2>
                                        <div className="form-group ">
                                            <label>Enter OTP Provide in Email</label>
                                            <div className='d-flex gap-3 w-100' style={{ gap: "10px" }}>
                                                <Input
                                                    className="mb-3"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setOtp1(e.target.value)
                                                        const maxLength = parseInt(e.target.maxLength, 10);
                                                        const currentLength = e.target.value.length;

                                                        if (currentLength === maxLength) {
                                                            // Move focus to the next input
                                                            if (input2Ref.current) {
                                                                input2Ref.current.focus();
                                                            }
                                                        }
                                                    }}
                                                    style={{ padding: "10px 8px", textAlign: "center", fontSize: "18px" }}
                                                    maxLength={1}
                                                    required
                                                    ref={input1Ref}
                                                />
                                                <Input
                                                    className="mb-3"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setOtp2(e.target.value)
                                                        const maxLength = parseInt(e.target.maxLength, 10);
                                                        const currentLength = e.target.value.length;

                                                        if (currentLength === maxLength) {
                                                            // Move focus to the next input
                                                            if (input3Ref.current) {
                                                                input3Ref.current.focus();
                                                            }
                                                        }
                                                    }}
                                                    style={{ padding: "10px 8px", textAlign: "center", fontSize: "18px" }}
                                                    required
                                                    maxLength={1}
                                                    ref={input2Ref}
                                                />
                                                <Input
                                                    className="mb-3"
                                                    type="text"
                                                    onChange={(e) => {
                                                        setOtp3(e.target.value)
                                                        const maxLength = parseInt(e.target.maxLength, 10);
                                                        const currentLength = e.target.value.length;

                                                        if (currentLength === maxLength) {
                                                            // Move focus to the next input
                                                            if (input4Ref.current) {
                                                                input4Ref.current.focus();
                                                            }
                                                        }
                                                    }}
                                                    style={{ padding: "10px 8px", textAlign: "center", fontSize: "18px" }}
                                                    required
                                                    maxLength={1}
                                                    ref={input3Ref}
                                                />
                                                <Input
                                                    className="mb-3"
                                                    type="text"
                                                    onChange={(e) => setOtp4(e.target.value)}
                                                    style={{ padding: "10px 8px", textAlign: "center", fontSize: "18px" }}
                                                    required
                                                    maxLength={1}
                                                    ref={input4Ref}
                                                />
                                            </div>

                                            {error == true ? (
                                                <div>
                                                    <span className="text-danger font-12">
                                                        Please Enter Correct OTP
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group submit">
                                            <button
                                                type="submit"
                                                className="ps-btn ps-btn--fullwidth bg-blue color-white"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="mt-20 text-center">
                                            <p className="mb-0">Dont have an account?</p>
                                            <Link to="/users/registration" className="color-blue" style={{ fontWeight: "600" }}>Register Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <form className="ps-form--account pt-0" onSubmit={onHandleReset}>
                                <div className="ps-tab active border-radius3 bd-login-page">
                                    <div className="ps-form__content p-5 ">
                                        <h2 className="pb-20 text-center">Reset Password</h2>
                                        <div className="form-group ">

                                            <div className="position-relative">
                                                <Input
                                                    className="form-control mb-3"
                                                    type={show_password ? "text" : "password"}
                                                    placeholder="Enter New Password"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                <div className="bd-eye-open">
                                                    <span onClick={() => setShowPassword(!show_password)}>
                                                        <i
                                                            className={
                                                                show_password ? "fa fa-eye" : "fa fa-eye-slash"
                                                            }
                                                        ></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <Input
                                                className=" form-control mb-3"
                                                type="password"
                                                onChange={(e) => setPassWordReset(e.target.value)}
                                                required
                                                placeholder='Re-Enter New Password'
                                            />
                                        </div>
                                        <div className="form-group submit mt-3">
                                            <button
                                                type="submit"
                                                className="ps-btn ps-btn--fullwidth bg-blue color-white"
                                            >
                                                Reset Password
                                            </button>
                                        </div>
                                        <div className="mt-20 text-center">
                                            <p className="mb-0">Dont have an account?</p>
                                            <Link to="/users/registration" className="color-blue" style={{ fontWeight: "600" }}>Register Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}

                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

export default Otp