import React from 'react';
import HeaderMarketPlace from '../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../components/shared/headers/HeaderMobile';
import PageContainer from '../components/layouts/PageContainer';
import FooterMarketPlace2 from '../components/shared/footers/FooterMarketPlace2';
import NotFoundIMg from "../assets/static/img/404.jpg"
import { Link } from 'react-router-dom';

function NotFound() {
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;
    return (
        <PageContainer header={headers} footer={footer} boxed={true}>
            <div className="site-content">
                <div className="ps-page--404">
                    <div className="containers">
                        <div className="ps-section__content pt-5">
                            <figure>
                                <img src={NotFoundIMg}  loading="lazy" alt="companyImage" height='240' className='mb-4' />
                                <h3>Ohh! Page not found</h3>
                                <p>
                                    It seems we can't find what you're looking for.{' '}
                                    <br />
                                    Go back to
                                    <Link to="/">
                                        <a> Homepage</a>
                                    </Link>
                                </p>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}

export default NotFound;
