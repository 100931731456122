import React, { useEffect, useState } from "react";
import PageContainer from "../../components/layouts/PageContainer";
import Loader from "../Loader";
import WidgetShopCategories from "../../components/shared/widgets/WidgetShopCategories";
import { Slider } from "antd";
import ReactPaginate from "react-paginate";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import BreadCrumb from "../../components/elements/BreadCrumb";
import NoImg from "../../assets/static/img/w-logo.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useRef } from "react";
import config from "../../config";


const Serach = () => {

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const location = useLocation();

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Categories",
      url: "/",
    },
  ];

  //Views

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  useEffect(() => {
    fetchDryWallProduc();
  }, [location?.search?.split("=")[1]?.toLowerCase()]);

  const [isLoading, setLoading] = useState(false);
  const [product_list, setProductList] = useState([]);
  const [allData, setAllData] = useState([]);


  const fetchDryWallProduc = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/allproducts`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        const filterData = res.data.data.filter((item) =>
          item.name
            .toLowerCase()
            .includes((decodeURIComponent(location?.search?.split("=")[1]?.replace(/\+/g, ' '))).toLowerCase())
        );
        setProductList(filterData);
        setAllData(filterData);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isCalled, setCalled] = useState(false);
  const [itemsPerPage, setitemsPerPage] = useState(30);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(product_list.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(product_list.length / itemsPerPage));
  }, [product_list, isCalled]);

  const targetDivRef = useRef(null);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const targetDiv = targetDivRef.current;
    targetDiv.scrollIntoView({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setCalled(!isCalled);
    const newOffset = (event.selected * itemsPerPage) % product_list.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    let maxPrice;
    let minPrice;
    if (allData?.length >= 1) {
      minPrice = parseFloat(allData[0]?.price);
      maxPrice = parseFloat(allData[0]?.price);
    }

    // Iterate through the array to find the minimum and maximum prices
    allData.forEach((item) => {
      const price = parseFloat(item.price);
      if (price < minPrice) {
        minPrice = price;
      }
      if (price > maxPrice) {
        maxPrice = price;
      }
    });
    setMin(minPrice);
    setMax(maxPrice);

    setSliderValue([minPrice, maxPrice]);
  }, [allData]);

  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [slider_value, setSliderValue] = useState([]);

  const onChangeValue = (e) => {
    setSliderValue(e);
    const filteredProducts = allData.filter((product) => {
      const price = product.main_price.split("£")[1];
      return price >= e[0] && price <= e[1];
    });
    setProductList(filteredProducts);
  };

  const onChangeBrand = (e) => {
    if (e.target.value != "" && e.target.value != "all") {
      const filterData = allData.filter((obj) => obj.brand == e.target.value);
      setProductList(filterData);
    } else {
      setProductList(allData);
    }
  };

  const onSortBy = (e) => {
    if (e.target.value == "") {
      setProductList(product_list);
      setCalled(!isCalled);
    } else if (e.target.value == "Newest") {
      setCalled(!isCalled);
      setProductList(product_list.sort((a, b) => b.id - a.id));
    } else if (e.target.value == "Oldest") {
      setCalled(!isCalled);
      setProductList(product_list.sort((a, b) => a.id - b.id));
    } else if (e.target.value == "Low-High") {
      setCalled(!isCalled);
      setProductList(
        product_list.sort(
          (a, b) => a.main_price?.split("£")[1] - b.main_price?.split("£")[1]
        )
      );
    } else if (e.target.value == "High-Low") {
      setCalled(!isCalled);
      setProductList(
        product_list.sort(
          (a, b) => b.main_price?.split("£")[1] - a.main_price?.split("£")[1]
        )
      );
    }
  };

  const [all_category, setAllCategory] = useState([])

  useEffect(() => {
    if (all_category?.length == 0) {
      onAllCategory()
    }
  }, [])


  const onAllCategory = async (e) => {
    // setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/categories`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setAllCategory(res.data)
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <PageContainer header={headers} footer={footer} boxed={true}>
        {isLoading && <Loader />}
        <div className="ps-page--shop" ref={targetDivRef}>
          <BreadCrumb breacrumb={breadCrumb} />
          <div className="container">
            <div className="ps-layout--shop ps-shop--category">
              <div className="ps-layout__left">
                <aside className="widget widget_shop">
                  <h4 className="widget-title">Categories</h4>
                  <ul className="ps-list--categories colo-black">
                    {all_category.map((obj , i) => {
                      return (
                        <li className="" key={i}>
                          <Link to={`/${obj.slug}`}>{obj.name}</Link>
                        </li>
                      )
                    })}

                  </ul>
                </aside>

                <aside className="widget widget_shop">
                  <figure>
                    <h4 className="widget-title">By Price</h4>
                    <Slider
                      range
                      value={slider_value}
                      onChange={onChangeValue}
                      max={max}
                      min={min}
                    />
                    <p>
                      Price: £ {min} - £ {max}
                    </p>
                  </figure>
                </aside>
              </div>
              <div className="ps-layout__right">
                <h3 className="ps-shop__heading">
                  Search result for "{decodeURIComponent(location?.search?.split("=")[1]?.replace(/\+/g, ' '))}"{" "}
                </h3>
                <div className="ps-shopping pb-0">
                  <div className="ps-shopping__header">
                    <p>
                      <strong className="mr-2">{product_list?.length}</strong>
                      Products found
                    </p>
                    <div className="ps-shopping__actions">
                      <select
                        className="ps-select form-control"
                        data-placeholder="Sort Items"
                        onChange={onChangeBrand}
                      >
                        <option value="">Sort by Brands</option>
                        <option value="all">All Brands</option>
                        <option value="Standard">Standard</option>
                        <option value="Sheet">Sheet</option>
                        <option value="Linear Component">
                          Linear Component
                        </option>
                        <option value="Linear">Linear</option>
                      </select>
                      <select
                        className="ps-select form-control ml-10"
                        data-placeholder="Sort Items"
                        onChange={onSortBy}
                      >
                        <option value="">Sort By</option>
                        <option value="Newest">Newest</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Low-High">Price: Low to High</option>
                        <option value="High-Low">Price: High to Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    {currentItems.map((items, i) => {
                      const stars = [];
                      for (let i = 1; i <= 5; i++) {
                        const className =
                          i <= items.rating ? "fa fa-star" : "fa fa-star-o";
                        stars.push(<i className={className} key={i}></i>);
                      }

                      let maxPrice = 0;
                      let minPrice = 0;
                      if (items?.stock?.length >= 1) {
                        minPrice = parseFloat(items?.stock[0]?.price);
                        maxPrice = parseFloat(items?.stock[0]?.price);
                      }


                      let Price
                      if (items?.stock?.length == 1) {
                        Price = "£" + parseFloat(items?.stock[0]?.price).toFixed(2);
                      } else {
                        items?.stock?.forEach((item) => {
                          const price = parseFloat(item.price).toFixed(2);
                          if (price < minPrice) {
                            minPrice = price;
                          }
                          if (price > maxPrice) {
                            maxPrice = price;
                          }
                        });
                        Price = "£" + minPrice + " - " + "£" + maxPrice;
                      }

                      return (
                        <div key={i} className="col-md-4 pl-0 pr-0">
                          <div className="ps-product ps-product--inner all-product-card h-340">
                            <div className="ps-product__thumbnail text-center">
                              {items.thumbnail_image != null ? (
                                <Link to={`/product/${items.slug}`}>
                                  <img
                                    src={items.thumbnail_image}
                                     loading="lazy" alt="No img"
                                    height="171"
                                    style={{ objectFit: "cover" }}
                                  />
                                </Link>
                              ) : (
                                <div style={{ height: "171px" }}>
                                  <Link to={`/product/${items.slug}`}>
                                    <img
                                      src={NoImg}
                                       loading="lazy" alt="No img"
                                      height='171'
                                      className="mt-15"
                                    />
                                  </Link>
                                </div>
                              )}

                              <ul className="ps-product__actions">
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Add To Cart"
                                  >
                                    <i className="icon-bag2"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="ps-product__container">
                              <Link
                                to={`/product/${items.slug}`}
                                className="ps-product__vendor"
                                href="/shop"
                              >
                                {items.name}
                              </Link>
                              <div className="ps-product__rating text-center" >
                                <span className="ps-rating">{stars}</span>
                              </div>
                              <div className="ps-product__content">
                                <div className="text-center price-fixed">
                                  <p className="ps-product__price sale" style={{ fontSize: "16px" }}>
                                    <span className="text-black" style={{ color: "black" }}> Price Range :</span>  <br /> {Price}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-30">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next "
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"ant-pagination-item-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"ant-pagination-item-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"ant-pagination-item-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"ant-pagination-item-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Serach;
