import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'; 
import discountImg from "../../assets/static/img/discountImage.png"

const OfferPopup = () => {
    const [open, setOpen] = useState(false);

    const location = useLocation()

    useEffect(() => {
        function handleShowPopup() {
            if (window.scrollY > 200 && location.pathname !== '/checkout') {
                setOpen(true)
                document.removeEventListener('scroll', handleShowPopup)
            }
        }
        document.addEventListener('scroll', handleShowPopup)
    }, [])

    return (
        <div>
            <Modal
                open={open}
                centered
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={650}
                footer={<div className="footer-none"></div>}
                className='offer-popup'
            >
                <div className='d-md-flex flex-md-row flex-column-reverse justify-content-between'>
                    <div className="col">

                        <div className='py-5 px-3'>
                            <h4 className='text-uppercase'
                                style={{
                                    color: '#09c',
                                    fontWeight: '600',
                                    marginBottom: '20px'
                                }}
                            >Did you know?</h4>

                            <h2 style={{
                                fontSize: '25px',
                                lineHeight: '35px',
                                fontWeight: 600
                            }}>
                                Enjoy <b style={{ color: '#09c' }}>10%</b> discount instantly on orders over £300!
                                <br /> Shop Now & Save Big!
                            </h2>

                            <div className='mt-5'>
                                <button
                                    onClick={() => setOpen(false)}
                                    className="btn-blue text-center w-100 d-block p-4">
                                    Continue Shopping
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* https://images.pexels.com/photos/7564195/pexels-photo-7564195.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1 */}
                    <div className="col offer_bg_img "
                        style={{
                            backgroundImage: `url(${discountImg})`, 
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OfferPopup