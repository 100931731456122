import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import config from '../../config';
import axios from 'axios';
import moment from 'moment';

import discountImg from "../../assets/static/img/discountImage.png"

const AllOffers = () => {
    const [open, setOpen] = useState(false);
    const [offers, setOffers] = useState([]);



    useEffect(() => {
        const fetchImage = async () => {
            const formData = new FormData();
            formData.append("type", 1);
            try {
                await axios.post(`${config.apiUrl}/offer_image`, formData).then((res) => {
                    setOffers(
                        [
                            {
                                title: `Instant 10% Discount `,
                                subtitle: "Purchase above £300 & Get the Discount",
                                image: discountImg,
                                link: ``
                            },
                            {
                                title: `${moment().format('MMMM')} deals`,
                                subtitle: "Collected from horrow depot only!",
                                image: res.data.data?.offer,
                                link: `${config.appUrl}offers`
                            },
                            {
                                title: `Inventory flash sale`,
                                subtitle: "Grab your favourites before they're Gone!",
                                image: res.data.data?.spot_deal[0]?.image,
                                link: `${config.appUrl}spot-deals`
                            },
                            {
                                title: `Limited Time Offers`,
                                subtitle: "Unbeatable deals inside",
                                image: res.data.data?.spot_deal[1]?.image,
                                link: `${config.appUrl}spot-deals-category`
                            }
                        ]
                    );
                });
            } catch (error) {
                console.log(error);
            }
        };
        fetchImage();
    }, []);


    return (
        <div>
            <div className='offer-gift shadow rounded-circle' onClick={() => setOpen(true)}>
                <i className='icon-gift'></i>
                <div>4</div>
            </div>

            <Modal
                open={open}
                title="Offers for you"
                centered
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={600}
                footer={<div className="footer-none"></div>}
            >
                <div >
                    <div>
                        {
                            offers.map((offer) => {
                                return (
                                    <>
                                        <Link to={offer.link}
                                            className='d-block offer-list text-decoration-none w-100'>
                                            <div
                                                className='d-flex align-items-center 
                                                        justify-content-between py-4 border-bottom'
                                                onClick={() => setOpen(false)}
                                            >
                                                <div className='d-sm-flex align-items-center pl-3'>
                                                    <div>
                                                        {
                                                            offer.image ?
                                                                <img src={offer.image} alt="OfferImage"
                                                                    height={40} width={100}
                                                                    className='border'
                                                                    style={{ objectFit: 'contain' }}
                                                                /> :
                                                                <div
                                                                    className='d-flex align-items-center justify-content-center'
                                                                    style={{
                                                                        backgroundColor: '#07395c',
                                                                        color: '#fff', height: 40, width: 100
                                                                    }}>
                                                                    Get Now
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className='ml-sm-4 mt-sm-0 mt-3'>
                                                        <h3 className='mb-0'>{offer.title}</h3>
                                                        <p className='mb-0'>{offer.subtitle}</p>
                                                    </div>
                                                </div>

                                                <div className='pr-4'>
                                                    {
                                                        offer.link &&
                                                        <i className='icon-arrow-right'></i>
                                                    }
                                                </div>

                                            </div>
                                        </Link>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AllOffers