import React, { useState } from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb'; 
import Image1 from "../../assets/static/img/about3.png"
import Image2 from "../../assets/static/img/about4.png"
import Image3 from "../../assets/static/img/about2.png"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { notification } from 'antd';
import Loader from '../Loader';
import config from '../../config';
import { Helmet } from 'react-helmet';

const Customised = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Bespoke Machine Services",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setLoading] = useState(false)

    const onGetinTouch = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("description", message);
        formData.append("subject", subject);
        try {
            const response = await axios({
                method: "post",
                url: `${config.apiUrl}/get_in_touch`,
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                notification.open({
                    message: (
                        <div className="d-flex">
                            <div className="pr-20">
                                <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
                            </div>
                            <div>Thank you for visiting Whanson Group</div>
                        </div>
                    ),
                    description: "Our Team Will Contact You Soon",
                });
                setLoading(false);
                setName("");
                setPhone("");
                setEmail("");
                setSubject("");
                setMessage("");

            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <div>
            <Helmet>
                <title>Bespoke Machine Services by W-Hanson UK for Precision Needs</title>
                <meta name="description" content="W-Hanson UK provides bespoke machine services to meet your specific requirements with precision and expertise. Visit us to learn more about our tailored solutions." />
            </Helmet>
            {isLoading && (<Loader />)}
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <img src={Image3} height="300" style={{ width: "100%", objectFit: "cover", }}  loading="lazy" alt="companyImage" />
                    <div className="ps-page--my-account">
                        <BreadCrumb breacrumb={breadCrumb} />
                        <div className="ps-section--custom pb-0">
                            <div className="container">
                                <div className="ps-section__header">
                                    <h1 className="text-center" style={{ fontWeight: "550" }}>Bespoke Machine Services</h1>
                                </div>
                                <div className="ps-section__content term-section mt-40">
                                    <div className='pb-90 mb-5'>
                                        <div className="row justify-space-between">
                                            <div className='col-md-5'>
                                                <div className='position-relative'>
                                                    <img src={Image1}  loading="lazy" alt='No Img' className='bd-first-img' style={{ objectFit: "cover" }} />
                                                    <img src={Image2}  loading="lazy" alt='No Img' className='bd-second-img' width='280' />
                                                </div>
                                            </div>
                                            <div className='col-md-6 bd-custom-text' style={{ paddingLeft: "0px" }} >
                                                <div>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>At W.Hanson, we are extremely proud to have built up a strong reputation for producing various skirtings, architraves, handrails, claddings, floorings and much more. Whether this being from our large portfolio of cutters, or by having new cutters produced to match up to existing patterns. This being particularly useful in London where there are a lot of Victorian & Georgian properties that require updating. We can machine this is both hardwood & softwood, and even MDF. </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>We can offer these services using a host of machinery in our timber machining mill, including a 5
                                                        cutter moulder, spindle moulders, a planer thicknesser, a table saw and an overhand planer, to get
                                                        timber machined to your exact required patterns and sizes, and to the quality you expect from any
                                                        high end joinery company. </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>We also have a designated door lining machine shop, which is working 24/7 to produce door lining packs in house plots, for our large Housebuilder customers. This is a market we are particularly strong in and have a strong reputation. </p>
                                                    <p className='customised-text'>
                                                        <i className='icon-arrow-right pr-10'></i>On top of this, we can also cut sawn constructional timbers using one of a few resaw machines that we have. We also use these to produce arris rails, featheredge, and firrings to your specifications.  </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='form-bg-blue'>
                                <div className='container'>
                                    <div className=' row justify-content-center pt-50 pb-50' >
                                        <div className='col-md-5'>
                                            <div style={{ backgroundImage: "url('https://images.unsplash.com/photo-1615840287214-7ff58936c4cf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y29udGFjdCUyMHVzfGVufDB8fDB8fHww')", borderRadius: "5px" }}>
                                                <div className='overlay-service'>
                                                    <div className='contact-service-detail '>
                                                        <div>
                                                            <h1>Contact Details</h1>
                                                        </div>
                                                        <div className='mb-5'>
                                                            <div className='d-flex'>
                                                                <div className='contact-info-service text-center'><i className='fa fa-phone '></i></div>
                                                                <div>
                                                                    <h4>Call Us</h4>
                                                                    <p className='mb-0'> 020-8571-3161</p>
                                                                    <p className='mb-0'> 020-8864-0822</p>
                                                                    <p className='mb-0'> 020-8845-2173</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex'>
                                                                <div className='contact-info-service text-center'><i className='fa fa-envelope '></i></div>
                                                                <div>
                                                                    <h4>Write to Us</h4>
                                                                    <p className='mb-0'> websales@w-hanson.co.uk</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="ps-form--contact-us col-md-7 career-form" onSubmit={onGetinTouch}>
                                            <div className=' mb-5 pb-5'>
                                                <h1 className='' style={{ fontSize: '38px' }}>Get In Touch</h1>
                                                <h5 style={{ fontSize: '18px' }}>Fill the Form</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Name *"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            placeholder="Enter Email *"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Subject *"
                                                            value={subject}
                                                            onChange={(e) => setSubject(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder="Can you clarify what you need? What are your requirements? "
                                                            value={message}
                                                            required
                                                            onChange={(e) => setMessage(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group submit ">
                                                <button className="ps-btn bg-blue color-white">Send message</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Customised